import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../composants/image/OpenSeaDragonViewer"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "tresorsCygneZoom" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function TresorsCygne(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "12387", Height: "14628" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/cygne/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Une mosaïque de 180 mégas pixels, 12 tuiles et 31 heures de poses
          d’une riche région de la constellation du Cygne, prise durant tout
          l’été 2020 avec un double setup EOS 6D modifié / 300mm F4 L. Les
          connaisseurs y repéreront les nombreux objets de la région : les
          nébuleuses de l’Amérique du Nord et du Pélican, le rémanent de
          supernova des Dentelles, la nébuleuse de la Tulipe, la nébuleuse du
          Croissant et bien d’autres encore.
        </p>
      </section>
      <div id="galerie-image-interactive-texte-exifs">
        Double Canon EOS 6D refiltré Astrodon, Canon EF 300 mm F4 L @ F5, 3200
        ISO. Mosaïque de 12 images. Temps pose individuel : 3 minutes, temps de
        pose total : 31 heures.
      </div>
    </LayoutGalerieImageInteractive>
  )
}
